//Projects
export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';
export const PROJECT_ERROR = 'PROJECT_ERROR';
export const ADD_PROJECT = 'ADD_PROJECT';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

//Services
export const GET_SERVICES = 'GET_SERVICES';
export const SERVICE_ERROR = 'SERVICE_ERROR';
export const ADD_SERVICE = 'ADD_SERVICE';
export const EDIT_SERVICE = 'EDIT_SERVICE';
export const DELETE_SERVICE = 'DELETE_SERVICE';

//Staff members
export const GET_MEMBERS = 'GET_MEMBERS';
export const MEMBER_ERROR = 'MEMBER_ERROR';
export const ADD_MEMBER = 'ADD_MEMBER';
export const EDIT_MEMBER = 'EDIT_MEMBER';
export const DELETE_MEMBER = 'DELETE_MEMBER';

//Testimonials
export const GET_TESTIMONIALS = 'GET_TESTIMONIALS';
export const TESTIMONIAL_ERROR = 'TESTIMONIAL_ERROR';
export const ADD_TESTIMONIAL = 'ADD_TESTIMONIAL';
export const EDIT_TESTIMONIAL = 'EDIT_TESTIMONIAL';
export const PUBLISH_TESTIMONIAL = 'PUBLISH_TESTIMONIAL';
export const DELETE_TESTIMONIAL = 'DELETE_TESTIMONIAL';

//User
export const GET_USERS = 'GET_USERS';
export const DELETE_USER = 'DELETE_USER';
export const CHANGE_ROLE = 'CHANGE_ROLE';
export const USER_ERROR = 'USER_ERROR';

//Authentication
export const LOADING = 'LOADING';
export const AUTHENTICATED = 'AUTHENTICATED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const UPDATE_AUTHOR = 'UPDATE_AUTHOR';

//Payments
export const GET_PAYMENTS = 'GET_PAYMENTS';
export const DECIDE_ON_PAYMENTS = 'DECIDE_ON_PAYMENTS';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const PAYMENT_LOADING = 'PAYMENT_LOADING';
