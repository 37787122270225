import { makeStyles } from '@material-ui/core/styles';

export const certificateStyles = makeStyles((theme) => ({
    root: {
      padding: "10px",
      maxWidth: '820px',
      backgroundColor: '#C4CDD5',
      overflow: "hidden"
    },
    downloadWrapper: {
      borderRadius: 'lg',
      maxWidth: '800px',
      position: 'relative',
    },
    certificateImage: {
      display: 'block',
      width: '100%',
      height: 'auto',
    },
    certificatePersonName: {
      position: 'absolute',
      color: '#13562f',
      fontWeight: 'bold',
      inset: 0,
      width: "135px",
      height: "135px",
      borderRadius: "50%",
      border: "2px solid #13562f",
      objectFit: 'cover',
      transform: 'translateY(-190%)',
      margin: "auto"
    },
    certificateText: {
      position: 'absolute',
      color: '#13562f',
      fontWeight: 'bold',
      inset: 0,
      transform: 'translateY(-14%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: '44px',
      '&.long-text': {
        fontSize: '29px',
        paddingLeft: theme.spacing(25),
        paddingRight: theme.spacing(25),
        transform: 'translateY(30%)'
      },
      '&.Company': {
        transform: 'translateY(-19%)',
      },
    },
    certificateDescription: {
      position: 'absolute',
      inset: 0,
      fontFamily: 'Lora',
      lineHeight: '30px',
      textAlign: 'center',
      transform: 'translateY(-2%)',
      display: 'flex',
      fontSize: "15px",
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: '9%',
      paddingRight: '9%',
    },
    notPracticingText: {
      position: 'absolute',
      inset: 0,
      fontFamily: 'Lora',
      lineHeight: '30px',
      transform: 'translateY(2.2%)',
      color: 'red',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    registrationNumber: {
      position: 'absolute',
      inset: 0,
      color: '#13562f',
      transform: 'translateY(7%)',
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 'bold',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: '17px',
    },
    certificateDate: {
      position: 'absolute',
      inset: 0,
      transform: 'translateX(35%) translateY(11.5%)',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      fontSize: '13.5px',
    },
    governingCouncil: {
      position: 'absolute',
      inset: 0,
      transform: 'translateX(35%) translateY(14%)',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      fontSize: '13.5px',
    },
    validUntilDate: {
      position: 'absolute',
      inset: 0,
      transform: 'translateX(35%) translateY(16.1%)',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      fontSize: '13.5px',
    },
    qrCodeContainer: {
      position: 'absolute',
      bottom: '3.5%',
      left: '43%', 
    },
    qrCode: {
      width: '110px',
      height: '110px',
    },
    warningContainer: {
      display: 'flex',
      gap: theme.spacing(2),
      justifyContent: 'center',
      alignItems: 'center',
    },
    warningIcon: {
      fontSize: '5rem',
      color: 'red',
    },
    warningText: {
      color: 'white',
      fontWeight: 'bold',
    },
    downloadButton: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(2, 7),
      border: '2px solid #03f',
      backgroundColor: '#03f2',
      color: '#09f',
      borderRadius: theme.spacing(2),
      fontWeight: 'bold',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
  }));